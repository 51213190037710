(self["webpackChunk_N_E"] = self["webpackChunk_N_E"] || []).push([[938],{

/***/ 20938:
/***/ (function(module) {

if (true) {
  module.exports = {
    ReactQueryDevtools: function () {
      return null
    },
    ReactQueryDevtoolsPanel: function () {
      return null
    },
  }
} else {}


/***/ })

}]);